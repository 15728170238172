import * as gqlb from "gql-query-builder";

export const SAVE_INTEGRATION_DELIVERY_AGENT = gqlb.mutation({
  operation: "saveIntegration",
  fields: ["id", "active", { deliveryAgent: ["id", "code", "name"] }],
  variables: {
    input: {
      type: "IntegrationInput",
      required: true,
    },
  },
});

export const SAVE_INTEGRATION_SERVICE = gqlb.mutation({
  operation: "saveIntegrationService",
  fields: ["id"],
  variables: {
    input: {
      type: "IntegrationServiceInput",
    },
  },
});

export const SAVE_INTEGRATION_ZONE = gqlb.mutation({
  operation: "saveIntegrationZone",
  fields: ["id"],
  variables: {
    input: {
      type: "IntegrationZoneInput",
    },
  },
});

export const DELIVERY_AGENT_ID = gqlb.query({
  operation: "deliveryAgent",
  fields: [
    "id",
    "name",
    {
      integration: ["id", "token", "integrationUrl", "active", "typeCode", "useSenderData", "useShipmentCode",
        { services: ["remoteId", { localSevice: ["name", "code", "id"] }] },
        { zones: ["remoteId", { localZone: ["name", "code", "id"] }, "parent"] },
        { cancellationReasons: ["remoteId", { localCancellationReason: ["name", "code", "id"] }] },
        { deliveryAgent: ["id", "code", "name"] }

      ]
    }
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const LIST_SHIPPING_SERVICES = gqlb.query({
  operation: "listShippingServices",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListShippingServicesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const LIST_ZONES = gqlb.query({
  operation: "listZones",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: ["id", "code", "name"],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListZonesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});



export const COMBINED_DROPDOWN_QUERY = `
query CombinedDropdown($zoneInput: ListZonesFilterInput, $serviceInput: ListShippingServicesFilterInput) {
  listZonesDropdown: listZonesDropdown(input: $zoneInput) {
    id
    name
  }
  listShippingServicesDropdown: listShippingServicesDropdown(input: $serviceInput) {
    id
    name
  }
}
`;

export const SUBZONES_DROPDOWN_QUERY = `
query ListZonesDropdown($zoneInput: ListZonesFilterInput) {
  listZonesDropdown: listZonesDropdown(input: $zoneInput) {
    id
    name
  }
}
`;

export const ME = `
query Me {
  me {
    id
  }
}
`;