import React, { useState, lazy, Suspense } from 'react'
import { styled } from "@mui/material/styles";
import { Box, Collapse, Paper, Stack, Typography } from '@mui/material';
import TitleAppBar from '../../../Layout/TitleAppBar';
import Grid from '@mui/material/Unstable_Grid2';
import MuiSwitch from '../../HOC/MUI/MUIswitch';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ControlMUItextField from '../../HOC/MUI/ControlMUItextField';
import EnumDropdown from '../../HOC/CustomComponents/EnumDropdown';
import ButtonLoading from '../../HOC/FunctionComponents/LoadingPages/ButtonLoading';
import FormButton from '../../CustomComponents/Buttons/FormButton';
import { COMBINED_DROPDOWN_QUERY, DELIVERY_AGENT_ID, ME, SAVE_INTEGRATION_DELIVERY_AGENT } from './Graphql';
import { useMutation, gql, useQuery } from "@apollo/client";
import { enumNameIsValid } from '../../HOC/CustomFunctions/lookupCodeIsValid';
import { useSnackbar } from 'notistack';
import { pushUrl } from '../../HOC/CustomFunctions/pushUrl';
import { setValidationError } from '../../HOC/CustomFunctions/setValidationError';
import CustomTab from '../../HOC/FunctionComponents/CustomTab';
import ServiceTable from './ServicesTable';
import FullScreenLoading from '../../HOC/FunctionComponents/LoadingPages/FullScreenLoading';

const ZonesTable = lazy(() => import("./ZonesTable"))

const PREFIX = "DeliveryAgentIntegrationForm";

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`,
  title: `${PREFIX}-title`,
  disableForm: `${PREFIX}-disableForm`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.spacing}`]: {
    position: "relative",
    width: "100%",
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(3),
  },
  [`& .${classes.disableForm}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const DeliveryAgentIntegrationForm = (props) => {
  const { t } = useTranslation(["translation", "validation"]);
  const deliveryAgentId = parseInt(props.match.params.id);
  const [checkValidationLoading, setCheckValidationLoading] = useState(false);
  const [zonesDropdown, setZonesDropdown] = useState([]);
  const [servicesDropdown, setServicesDropdown] = useState([]);
  const [servicesRemoteIds, setServicesRemoteIds] = useState([]);
  const [zonesRemoteIds, setZonesRemoteIds] = useState([]);
  const [subZonesRemoteIds, setSubZonesRemoteIds] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [servicesQueryData, setServicesQueryData] = useState(null);
  const [zonesQueryData, setZonesQueryData] = useState(null);

  const { handleSubmit, control, formState: { errors }, setValue, setError, watch } = useForm({
    defaultValues: {
      active: true,
      deliveryAgentId: deliveryAgentId,
    },
  })

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && enumNameIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const fetchCombinedDropdowns = async (token, url) => {
    // const token = watch("token");
    // const url = watch("integrationUrl");
    if (!token || !url) {
      return;
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: COMBINED_DROPDOWN_QUERY,
          variables: {
            zoneInput: { parentId: null },
            serviceInput: { active: true },
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.errors) {
        console.error('GraphQL errors:', data.errors);
        return null;
      }

      // Return both zones and services data
      return {
        zones: data.data.listZonesDropdown,
        services: data.data.listShippingServicesDropdown,
      };
    } catch (error) {
      console.error('Error fetching combined dropdowns:', error);
      return null;
    }
  };


  const { data: updateDeliveryAgent, loading: DeliveryAgentLoading } =
    useQuery(
      gql`
        ${DELIVERY_AGENT_ID.query}
      `,

      {
        skip: !deliveryAgentId,
        variables: { id: deliveryAgentId },
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          const integrationData = data.deliveryAgent.integration;

          const servicesIds = integrationData.services.map(e => {
            return {
              remoteId: e.remoteId,
              localId: e.localSevice.id
            }
          })
          setServicesRemoteIds(servicesIds)
          const zonesIds = integrationData.zones.filter(e => e.parent).map(e => {
            return {
              remoteId: e.remoteId,
              localId: e.localZone.id
            }
          })
          setZonesRemoteIds(zonesIds)
          const subZonesIds = integrationData.zones.filter(e => !e.parent).map(e => {
            return {
              remoteId: e.remoteId,
              localId: e.localZone.id
            }
          })
          setSubZonesRemoteIds(subZonesIds)

          setValue("active", integrationData.active);
          setValue("useShipmentCode", integrationData?.useShipmentCode);
          setValue("useSenderData", integrationData?.useSenderData);
          setValue("token", integrationData?.token);
          setValue("integrationUrl", integrationData?.integrationUrl);
          setValue("id", integrationData?.id);

          fetchCombinedDropdowns(integrationData?.token, integrationData?.integrationUrl)
            .then((data) => {
              setServicesDropdown(data.services ?? []);
              setZonesDropdown(data.zones ?? []);
            })
            .catch((error) => {
              console.error(error);
            });
        },
      }
    );

  const [saveIntegration, { loading: saveIntegrationLoading }] = useMutation(
    gql`
      ${SAVE_INTEGRATION_DELIVERY_AGENT.query}
    `
  );

  const checkVailedUrl = async (url, token) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: ME,
          variables: {},
        }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.errors) {
        console.error('GraphQL errors:', data.errors);
        return {
          status: false,
          field: "token",
          message: "notValid"
        };
      }
      // Return both zones and services data
      return {
        status: true,
      };
    } catch (error) {
      console.error('Error fetching combined dropdowns:', error);
      return {
        status: false,
        field: "integrationUrl",
        message: "notValid"
      };
    }
  };

  const onSubmit = (data) => {
    setCheckValidationLoading(true)
    checkVailedUrl(data.integrationUrl, data.token)
      .then((checkValidData) => {
        if (checkValidData.status) {
          saveIntegration({
            variables: {
              input: {
                ...data,
              },
            },
          })
            .then(() => {
              setCheckValidationLoading(false)
              enqueueSnackbar(t("saveSuccessful"), {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            })
            .catch(({ graphQLErrors }) => {
              setCheckValidationLoading(false)
              setValidationError(graphQLErrors, setError);
            });
        } else {
          setCheckValidationLoading(false)
          setError(checkValidData.field, {
            type: "validate",
            message: t(`validation:${checkValidData.message}`)
          })

        }
      })
  }



  const tapsArray = [
    {
      tabHead: <Typography>{t("services")}</Typography>,
      panel: (
        <ServiceTable
          servicesQueryData={servicesQueryData}
          setServicesQueryData={setServicesQueryData}
          servicesDropdown={servicesDropdown}
          servicesRemoteIds={servicesRemoteIds}
          setServicesRemoteIds={setServicesRemoteIds}
          title={"service"}
          integrationId={watch("id")}
        />
      ),
    },
    {
      tabHead: <Typography>{t("zonesAndSubzones")}</Typography>,
      panel: (
        <Suspense fallback={
          <Paper sx={{ mt: 2 }}>
            <FullScreenLoading minHeight={"200px"} />
          </Paper>
        }>
          <ZonesTable
            zonesQueryData={zonesQueryData}
            setZonesQueryData={setZonesQueryData}
            zonesDropdown={zonesDropdown}
            zonesRemoteIds={zonesRemoteIds}
            subZonesRemoteIds={subZonesRemoteIds}
            setSubZonesRemoteIds={setSubZonesRemoteIds}
            setZonesRemoteIds={setZonesRemoteIds}
            title={"service"}
            integrationId={watch("id")}
            integrationUrl={watch("integrationUrl")}
            token={watch("token")}
          />
        </Suspense>
      ),
    },
  ];

  if (DeliveryAgentLoading) {
    return <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  }
  return (
    <Root>

      <TitleAppBar path={props.match.path} viewDetails={{ name: updateDeliveryAgent?.deliveryAgent?.name, id: deliveryAgentId }} />
      <Stack spacing={2} p={2}>
        <Paper className={classes.spacing}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} m={0}>
              <Grid display={"flex"} justifyContent="end" xs={12}>
                <Box>
                  <MuiSwitch
                    edge="end"
                    name="active"
                    label={t("active")}
                    control={control}
                  />
                </Box>
                <Box>
                  <MuiSwitch
                    edge="end"
                    name="useShipmentCode"
                    label={t("enterShipmentCode")}
                    control={control}
                  />
                </Box>
                <Box>
                  <MuiSwitch
                    edge="end"
                    name="useSenderData"
                    label={t("enterSenderData")}
                    control={control}
                  />
                </Box>
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <EnumDropdown
                  name={"typeCode"}
                  label={t("type")}
                  control={control}
                  errors={errors}
                  variables={{ name: "IntegrationAdapterTypeCode" }}
                  rules={{ required: t("fieldIsRequired") }}

                  skip={DeliveryAgentLoading}
                  onCompleted={(data) =>
                    lookupsComplete(
                      data,
                      updateDeliveryAgent?.deliveryAgent?.integration?.typeCode,
                      "typeCode"
                    )
                  }
                />
              </Grid>
              <Grid xs={12} sm={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"token"}
                  label={t("token")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>

              <Grid xs={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  rules={{ required: t("fieldIsRequired") }}

                  name={"integrationUrl"}
                  label={t("integrationUrl")}
                />
              </Grid>

              <Grid xs={12} display={"flex"} justifyContent={"flex-end"}>
                <FormButton disabled={saveIntegrationLoading || checkValidationLoading}>
                  {(saveIntegrationLoading || checkValidationLoading) ? <ButtonLoading /> : t("save")}
                </FormButton>
              </Grid>
            </Grid>
          </form>
        </Paper>

        <CustomTab tapDetails={tapsArray} />

      </Stack>
    </Root>
  )
}

export default DeliveryAgentIntegrationForm
