import React, { Fragment, useState } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Typography, Paper, IconButton, Icon, Button, Stack, TextField } from "@mui/material";
import { Edit } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { gql, useMutation, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { GENERATE_USER_TOKEN, USER_ID } from "./Graphql";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { Globals } from "../HOC/Classes/Globals";
import BranchesTable from "./BranchesTable";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

const PREFIX = 'UserView';

const classes = {
  paper: `${PREFIX}-paper`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
  integrationPaper: `${PREFIX}-integrationPaper`,
};

const Root = styled(Stack)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  [`& .${classes.integrationPaper}`]: {
    padding: theme.spacing(2, 3),
    flexGrow: 1,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const UserView = (props) => {
  const { t } = useTranslation();
  const userId = props.match.params.id?.trim();
  const { loading, data } = useQuery(
    gql`
      ${USER_ID.query}
    `,

    {
      skip: !userId,
      variables: { id: parseInt(userId) },
      fetchPolicy: "no-cache",
      onCompleted: (data) => { },
    }
  );
  const [dialogState, setDialogState] = useState(false);
  const [token, setToken] = useState();
  const [copied, setCopied] = useState(false);
  const [generateToken] = useMutation(
    gql`
      ${GENERATE_USER_TOKEN.query}
    `
  );
  const generateUserToken = (data) => {

    generateToken({
      variables: {

        id: parseInt(userId)

      },
    })
      .then((data) => {
        setToken(data?.data?.generateUserToken);
        onOpenDialog()
      })
      .catch(({ graphQLErrors }) => {

        if (!graphQLErrors[0]["extensions"]?.validation) {
        }
      });
  };
  const onCloseDialog = () => {
    setDialogState(false);
  };

  const onOpenDialog = () => {
    setDialogState(true);
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(token).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }).catch((err) => {
      console.error('Could not copy text: ', err);
    });
  };


  const userBranches = data?.user?.userBranches?.map((i) => ({
    ...i,
    isDefault: i.default,
  }));

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/users/${userId}/edit`),
      icon: Edit,
      permission: data?.user?.editable
      // permission: canUpdateAdmin && "core.user.update",
    },
  ]

  if (loading) {
    return <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  }

  return !data?.user ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>

      <Root spacing={2} m={2}>
        {(Globals.settings.integration && data?.user?.account !== null) && <Paper className={classes.integrationPaper}>
          <Stack direction={"row"} justifyContent={"space-between"} flexWrap={"wrap"} alignItems={"center"} gap={2}>
            <Typography>{t("createTokenTitle")}</Typography>
            <Button
              variant="outlined"
              onClick={generateUserToken}
            >
              {t("create")}
            </Button>
          </Stack>
        </Paper>}
        <Paper container className={classes.paper} component={Grid}>
          <KeyValuePair
            title={t("username")}
            value={data?.user?.username}
          />
          <KeyValuePair
            title={t("active")}
            value={
              data?.user?.active ? (
                <Icon className={classes.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("role")}
            value={
              data?.user?.roles && (
                data?.user?.roles?.map((role, index) =>
                  <SpanLink key={role.id} className={classes.span}
                    pathname={`/admin/roles/${role.id}`}
                  >
                    {role.name} {!(data?.user?.roles.length - 1 === index) && ' , '}
                  </SpanLink>
                )
              )
            }
          />

          <KeyValuePair
            title={t("account")}
            // value={data?.user?.account?.name}
            value={
              data?.user?.account ? (
                <SpanLink
                  pathname={
                    data?.user?.roles?.some(el => el.id === 2)

                      ? `/admin/customers/${data?.user?.account?.id}`
                      : `/admin/delivery-agents/${data?.user?.account?.id}`
                  }
                >
                  {data?.user?.account?.name}
                </SpanLink>
              ) : (
                "--"
              )
            }
          />
        </Paper>
        <BranchesTable branches={userBranches} />
      </Root>

      {(Globals.settings.integration && data?.user?.account !== null) && <CustomDialog
        fullWidth
        maxWidth="xs"
        open={dialogState}
        onClose={onCloseDialog}
        title={
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h6" color={"text.primary"}>{t("token")}</Typography>
            <IconButton
              onClick={handleCopy}
              disabled={copied}
            >
              {copied ? <LibraryAddCheckIcon fontSize="inherit" /> : <ContentCopyIcon fontSize="inherit" />}
            </IconButton>
          </Stack>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
          </>
        }
        content={
          <TextField
            id="outlined-multiline-static"
            fullWidth
            multiline
            rows={4}
            defaultValue={token}
            disabled={true}
          />
        }
      />}
    </>
  )

};

export default UserView;
