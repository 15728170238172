import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
// import darkLogo from "../../assets/Image/logo-dark.png";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import * as gqlb from "gql-query-builder";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
// import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import "../ManifestPrint/print.css";

import formatMoney from "../../helpers/numbersDot";

import { customerZoom } from "../ManifestPrint/ManifestPrintFunc";

export {};
const PrefixPrintList = "DeliveryAgentDueInquiryPrint";

export const classes = {
  headerCells: `${PrefixPrintList}-headerCells`,
  title: `${PrefixPrintList}-title`,
  notes: `${PrefixPrintList}-notes`,
  noteCell: `${PrefixPrintList}-noteCell`,
  barcodeFont: `${PrefixPrintList}-barcodeFont`,
  cairoFont: `${PrefixPrintList}-cairoFont`,
  barcode: `${PrefixPrintList}-barcode`,
  headerTableCell: `${PrefixPrintList}-headerTableCell`,
  mainCellBorder: `${PrefixPrintList}-mainCellBorder`,
  signature: `${PrefixPrintList}-signature`,
  reportHeader: `${PrefixPrintList}-reportHeader`,
  hiddenFooter: `${PrefixPrintList}-hiddenFooter`,
  pageFooter: `${PrefixPrintList}-pageFooter`,
  logoSection: `${PrefixPrintList}-logoSection`,
  headerDate: `${PrefixPrintList}-headerDate`,
  whiteSpace: `${PrefixPrintList}-whiteSpace`,
  approve: `${PrefixPrintList}-approve`,
  rowWithoutBorder: `${PrefixPrintList}-rowWithoutBorder`,
  mainDetailsCell: `${PrefixPrintList}-mainDetailsCell`,
  tableHeadCell: `${PrefixPrintList}-tableHeadCell`,
  tableWidth: `${PrefixPrintList}-tableWidth`,
  shipmentsTable: `${PrefixPrintList}-shipmentsTable`,
  signatureBlock: `${PrefixPrintList}-signatureBlock`,
};
export const Root = styled("div")(({ theme }) => ({
  // fontFamily: ['"Cairo"', "sans-serif"].join(","),
  [`& .${classes.headerCells}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    color: theme.palette.info.main,
    // whiteS;pace: "break-spaces",
  },

  [`& .${classes.notes}`]: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.action.disabled,
    wordBreak: "break-all",
    width: "100%",
    minHeight: 80,
  },

  [`& .${classes.noteCell}`]: {
    width: "30%",
    overflow: "hidden",
  },

  [`& .${classes.barcodeFont}`]: {
    fontFamily: '"Libre Barcode 39 Text"',
  },

  [`& .${classes.cairoFont}`]: {
    // fontFamily: ['"Cairo"', "sans-serif"].join(","),
  },

  [`& .${classes.barcode}`]: {
    fontSize: 40,
    textAlign: "center",
  },

  [`& .${classes.headerTableCell}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.mainCellBorder}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.signature}`]: {
    textAlign: "center",
    padding: theme.spacing(2, 1, 1, 1),
    border: "none",
  },

  [`& .${classes.reportHeader}`]: {
    whiteSpace: "pre-line",
    padding: theme.spacing(0, 2),
    lineHeight: "120%",
    fontSize: 12,
  },

  [`& .${classes.hiddenFooter}`]: {
    visibility: "hidden",
  },

  [`& .${classes.pageFooter}`]: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid " + theme.palette.text.secondary,
    display: "flex",
    alignItems: "flex-end",
  },

  [`& .${classes.logoSection}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.headerDate}`]: {
    textAlign: "end",
  },

  [`& .${classes.whiteSpace}`]: {
    "& tr": {
      "& td": {
        whiteSpace: "initial",
      },
    },
  },

  [`& .${classes.approve}`]: {
    textAlign: "end",
  },

  [`& .${classes.rowWithoutBorder}`]: {
    "& td": {
      border: "none",
      padding: 0,
    },
  },

  [`& .${classes.mainDetailsCell}`]: {
    "& td": { border: "none", verticalAlign: "top" },
    "& td:first-of-type": { paddingLeft: 0 },
    "& td:last-of-type": { paddingRight: 0 },

    border: "none",
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.tableHeadCell}`]: {
    padding: 0,
  },

  [`& .${classes.tableWidth}`]: {
    width: "100vw",
  },

  [`& .${classes.shipmentsTable}`]: {
    "& tr": {
      "&:hover": {
        backgroundColor: "#ffffff00 !important",
      },
    },
    "& td , & th": {
      padding: theme.spacing(1),
    },
  },

  [`& .${classes.signatureBlock}`]: {
    breakInside: "avoid",
  },
}));

const org = {
  operation: "organization",
  fields: ["name"],
  variables: {},
};

const collectionsListFields = (fields) => {
  return [
    {
      data: [
        "id",
        "name",

        "code",

        {
          branch: ["id", "name"],
        },
        "commissionDueAmount",
        "collectionDueAmount",
      ],
    },
  ];
};

const PAGE_COUNT = gqlb.query({
  operation: "listDeliveryAgents",
  fields: [{ paginatorInfo: ["lastPage"] }],
  variables: {
    input: {
      type: "ListDeliveryAgentsFilterInput",
    },
    first: {
      type: "Int",
    },
  },
});

const PrintDeliveryAgentDueInquiry = (props) => {
  // useEffect(() => {
  //   document.body.style.backgroundColor = "#fff"
  //   const divElements = document.getElementsByTagName('div');

  //   console.log(divElements);

  //   // Iterate through the collection and apply styles
  //   for (let i = 0; i < divElements.length; i++) {
  //     const div = divElements[i];
  //     div.style.color = '#000'; // Example style: set background color to lightblue
  //     // Add other styles as needed
  //   }
  //   return () => {

  //   }
  // }, [])

  const urlQuery = urlParameters(window.location.search);

  const stringValues = ["code", "fromCode", "toCode"];
  stringValues.forEach((i) => {
    if (urlQuery[i]) urlQuery[i] = String(urlQuery[i]);
  });

  const paymentPermission = urlQuery.PP;

  delete urlQuery.PP;

  const { data: pageCount, loading: pageLoad } = useQuery(
    gql`
      ${PAGE_COUNT.query}
    `,
    {
      first: 100,
      variables: {
        input: {
          ...urlQuery,
          withDue: null,
          ...(urlQuery?.branchId && {
            branchId: { value: urlQuery.branchId, includeNull: false },
          }),
        },
        first: 100,
      },
    }
  );
  const pages = pageCount?.listDeliveryAgents?.paginatorInfo?.lastPage;

  const queryBuilder = () => {
    // const queryNumber = Math.ceil(pages / 100);
    const queryArray = [org];
    for (let index = 0; index < pages; index++) {
      const initialQuery = {
        operation: `listDeliveryAgents${index}:listDeliveryAgents`,
        fields: collectionsListFields(),
        variables: {
          input: {
            type: "ListDeliveryAgentsFilterInput",
          },
          first: {
            type: "Int",
          },
          ["page" + index]: {
            type: "Int",
            name: "page",
          },
        },
      };
      queryArray.push(initialQuery);
    }
    return queryArray;
  };

  const queryBody = queryBuilder();
  const COLLECTIONS_PRINT = gqlb.query(queryBody);

  let variables = {
    input: {
      ...urlQuery,
      withDue: null,
      ...(urlQuery?.branchId && {
        branchId: { value: urlQuery.branchId, includeNull: false },
      }),
    },
    first: 100,
  };
  for (let index = 0; index < pages; index++) {
    variables["page" + index] = index + 1;
  }

  const { data: queryData, loading } = useQuery(
    gql`
      ${COLLECTIONS_PRINT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !pages,
      variables,
      onError: () => {
        return;
      },
    }
  );

  let parsedData = [];

  if (queryData?.listDeliveryAgents0) {
    let concatData = [];
    for (const key in queryData) {
      if (key.startsWith("list")) {
        const listData = queryData[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }

  // useEffect(() => {
  //   if (parsedData.length > 0) {
  //     window.print();
  //   }
  //   return () => { };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [queryData]);

  const paymentMethodCode = urlQuery?.paymentMethodCode;
  const reportDetails = parsedData.length > 0;
  const [zoomed, setZoomed] = useState(false);
  useEffect(() => {
    if (parsedData) {
      if (parsedData.length === 0) return;
      if (!zoomed) {
        const zoomDetails = customerZoom("", "1897");
        setZoomed(true);
        let newLink = document.createElement("link");
        newLink.rel = "stylesheet";
        newLink.type = "text/css";
        newLink.href = process.env.PUBLIC_URL + "/" + zoomDetails?.css;

        document.getElementById("manifestPrintTable").style.zoom =
          zoomDetails.percent;

        document.querySelectorAll("#shipmentsTableContainer").forEach((i) => {
          i.style.minWidth = "100%";
          i.style.width = "100vw";
        });
        newLink.onload = () => {
          window.print();
          newLink.onload = null;
        };
        document.getElementById("maniContainer").appendChild(newLink);
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const table = !loading && !pageLoad && reportDetails !== null && (
    <Table id="manifestPrintTable">
      <EnhancedTableHead
        paymentMethodCode={paymentMethodCode}
        paymentPermission={paymentPermission}
      />

      <TableBody>
        {parsedData.length > 0 &&
          parsedData?.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                <FixedTableCell>{row?.name}</FixedTableCell>
                <FixedTableCell>{row?.code}</FixedTableCell>

                <FixedTableCell>{row?.branch?.name}</FixedTableCell>

                <FixedTableCell>
                  {formatMoney(row?.collectionDueAmount)}
                </FixedTableCell>
                <FixedTableCell>
                  {formatMoney(row?.commissionDueAmount)}
                </FixedTableCell>
                <FixedTableCell>
                  {formatMoney(
                    row?.collectionDueAmount - row?.commissionDueAmount
                  )}
                </FixedTableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );

  return (
    <Root id="maniContainer">
      {loading || pageLoad ? (
        <>
          <FullScreenLoading minHeight="20%" />
          {/* this spans for download font before print */}
          <span className={clsx(classes.barcodeFont)} />
          <span style={{ opacity: 0 }} className={classes.cairoFont}>
            lا
          </span>
        </>
      ) : reportDetails === null ? (
        <NotFound />
      ) : (
        <>
          <div id="printManifestContainer">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>
                    <div
                      className={clsx(classes.shipmentsTable, {
                        // [classes.tableWidth]: tableZoom,
                      })}
                      id="shipmentsTableContainer"
                    >
                      {table}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </Root>
  );
};

function EnhancedTableHead(props) {
  //*********Table Function*********
  const { t } = useTranslation();
  const headCells = [
    { numeric: true, disablePadding: false, label: t("name") },
    { numeric: true, disablePadding: false, label: t("code") },
    { numeric: true, disablePadding: false, label: t("branch") },
    { numeric: true, disablePadding: false, label: t("collectAmount") },
    { numeric: true, disablePadding: false, label: t("dueAmount") },
    { numeric: true, disablePadding: false, label: t("net") },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <FixedTableCell
              key={index}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </FixedTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default PrintDeliveryAgentDueInquiry;
