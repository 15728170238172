import * as gqlb from "gql-query-builder";

export const SAVE_INTEGRATION = gqlb.mutation({
  operation: "saveIntegration",
  fields: ["id", "active", "typeCode"],
  variables: {
    input: {
      type: "IntegrationInput",
      required: true,
    },
  },
});

export const SAVE_INTEGRATION_CANCELLATIONREASON = gqlb.mutation({
  operation: "saveIntegrationCancellationReason",
  fields: ["id"],
  variables: {
    input: {
      type: "IntegrationCancellationReasonInput",
    },
  },
});

export const CUSTOMER_ID = gqlb.query({
  operation: "customer",
  fields: [
    "id",
    "name",
    {
      integration: [
        "id",
        "token",
        "integrationUrl",
        "active",
        "typeCode",
        { cancellationReasons: ["remoteId", { localCancellationReason: ["name", "code", "id"] }] },
      ]
    }
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});



export const LIST_CANCELLATION_REASONS = gqlb.query({
  operation: "listCancellationReasons",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: ["id", "code", "name"],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListCancellationReasonsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const CANCELLATIONREASONS_DROPDOWN_QUERY = `
query listCancellationReasonsDropdown($input: ListCancellationReasonsFilterInput) {
 listCancellationReasonsDropdown(input: $input) {
    id
    name
  }
}
`;

export const ME = `
query Me {
  me {
    id
  }
}
`;