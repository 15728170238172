/* eslint-disable no-useless-concat */
import { gql, useQuery } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { Edit } from "@mui/icons-material";
import { Button, Icon, Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Can } from "../HOC/CustomComponents/Secured";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import PieCharts from "../Home/PieCharts";
import { CUSTOMERID, GetPaymentCycleDays } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import { GetPermissionSlug } from "../../helpers/getPermissionSlug";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import PhoneNumberActions from "../HOC/CustomComponents/PhoneNumberActions";

const PREFIX = "CustomerView";

const classes = {
  box: `${PREFIX}-box`,
  main: `${PREFIX}-main`,
  iconColor: `${PREFIX}-iconColor`,
  span: `${PREFIX}-span`,
  boxPie: `${PREFIX}-boxPie`,
  integrationBox: `${PREFIX}-integrationBox`,
};

const Root = styled(Stack)(({ theme }) => ({
  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  [`& .${classes.integrationBox}`]: {
    padding: theme.spacing(2, 3),
    flexGrow: 1,
  },
  [`& .${classes.boxPie}`]: {
    // padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.span}`]: {
    display: "inline",
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));
const formatDate = (date) => moment(date).locale("en").format("YYYY-MM-DD");
// const initToDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const CustomerView = (props) => {
  const week = useRef(7);
  const month = useRef(30);

  const { t } = useTranslation();

  const customerId = props.id ?? props.match?.params?.id?.trim();
  const [queryVariables, setQueryVariables] = useState({
    date: { fromDays: month.current },
    // toDate: moment(new Date()).locale("en").format("YYYY-MM-DD"),
    statusCode: null,
  });

  const onDateRangeAccept = (date, closeDateRangeDialog) => {
    setQueryVariables((prev) => ({
      ...prev,
      date: {
        fromDate: formatDate(date[0]),
        toDate: formatDate(date[1]),
      },
    }));
    closeDateRangeDialog();
  };
  const handleDateRange = (value, setSelectValue, setDateRangeDialog) => {
    let defaultParamter = {
      date: {},
    };

    switch (value) {
      case "month":
        setSelectValue("month");
        defaultParamter.date.fromDays = month.current;
        break;
      case "week":
        setSelectValue("week");
        defaultParamter.date.fromDays = week.current;
        break;
      case "all":
        setSelectValue("all");
        break;

      case "period":
      default:
        setSelectValue("period");

        return setDateRangeDialog(true);
    }

    setQueryVariables((prev) => ({
      statusCode: prev.statusCode,
      ...defaultParamter,
    }));
  };
  const [defaultValuesList, setDefaultValuesList] = useState();
  const { loading, data } = useQuery(
    gql`
      ${CUSTOMERID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(customerId) },
      onCompleted: (data) => {
        const paymentCycleSelected = data?.customer?.paymentCycle?.code;
        const selectedPaymentCycle = GetPaymentCycleDays(
          t,
          paymentCycleSelected
        )?.filter((day) => data?.customer?.paymentCycleDays.includes(day.id));
        const name = selectedPaymentCycle.map((ele) => ele.name);
        setDefaultValuesList(name.toString());
      },
    }
  );
  const hideMobileFromName = data?.customer?.hideMobileFrom?.map(
    (ele) => ele.name
  );
  const hideMobileFrom = hideMobileFromName
    ? hideMobileFromName.join(", ")
    : "";
  const isWarehousing = Globals.settings.warehousing;
  const user = Globals.user;
  const branchId = data?.customer?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const pagePermission =
    data &&
    user.hasPermission(
      GetPermissionSlug(
        "shipping",
        "customer",
        data?.customer?.type?.code,
        "update"
      )
    );

  const customerType = data?.customer?.type?.code;
  const canEdit = pagePermission && canAccessBranch;
  const paymentTypes = data?.customer?.paymentTypes
    ?.map((ele) => ele.name)
    .toString();

  const editURL = () => {
    pushUrl(props, `/admin/customers/${customerId}/edit`);
  };

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: canEdit,
    },
  ];

  console.log(customerType);
  

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.customer ? (
    <NotFound />
  ) : (
    <>
      <TitleAppBar
        path={props.id ? props?.props?.match?.path : props?.match?.path}
        type={customerType}
      >
        <LongMenu icons={icons} />
      </TitleAppBar>

      <Root spacing={2} m={2}>
        {Globals.settings.integration && customerType === "MERCHANT" && Globals.user.hasPermission("core.integration.save") &&
          <Paper className={classes.integrationBox} >
            <Stack direction={"row"} justifyContent={"space-between"} flexWrap={"wrap"} alignItems={"center"} gap={2}>
              <Typography>{t("integrationTitle")}</Typography>
              {
                <SpanLink
                  pathname={`/admin/customers/merchant/${data?.customer?.id}/integrations`}
                >
                  <Button variant="outlined">{data?.customer?.integration ? t("update") : t("create")}</Button>
                </SpanLink>
              }
            </Stack>
          </Paper>
        }
        <Paper container className={classes.box} component={Grid}>
          <KeyValuePair title={t("code")} value={data?.customer.code} />
          <KeyValuePair
            title={
              customerType === "MERCHANT" ? t("storeName") : t("individualName")
            }
            value={data?.customer.name}
          />
          <KeyValuePair
            title={t("createdAt")}
            value={data?.customer.createdAt}
          />
          <KeyValuePair
            title={t("active")}
            value={
              data?.customer?.active ? (
                <Icon className={classes.iconColor}>check_circle_outline</Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("enterShipmentCode")}
              value={
                data?.customer?.specifyShipmentCode ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("taxable")}
              value={
                data?.customer?.taxable ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("shareCollectedFees")}
              value={
                data?.customer?.shareCollectedFees ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("payUncollected")}
              value={
                data?.customer?.payUncollected ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("deliveryOtp")}
              value={
                data?.customer?.sendDeliveryOtp ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
          )}
          {isWarehousing && customerType === "MERCHANT" && (
            <KeyValuePair
              title={t("warehousing")}
              value={
                data?.customer?.warehousing ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
          )}
          <KeyValuePair
            title={t("branch")}
            value={
              data?.customer?.branch?.name && (
                <SpanLink
                  pathname={`/admin/branches/${data?.customer?.branch?.id}`}
                >
                  {data?.customer?.branch?.name}
                </SpanLink>
              )
            }
          />
          <KeyValuePair
            title={t("glAccount")}
            value={data?.customer.glAccount?.name}
          />
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("source")}
              value={
                <SpanLink pathname={`/admin/zones/${data?.customer.zone.id}`}>
                  {data?.customer?.zone?.name +
                    " / " +
                    data?.customer.subzone.name}
                </SpanLink>
              }
            />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("priceType")}
              value={data?.customer.defaultPriceType?.name}
            />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("packageType")}
              value={data?.customer.defaultShipmentType?.name}
            />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair title={t("paymentTypes")} value={paymentTypes} />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("packageOpenPossibility")}
              value={data?.customer.defaultOpenable?.name}
            />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("customerCategories")}
              value={data?.customer.category?.name}
            />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("name")}
              value={data?.customer.representative}
            />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("activity")}
              value={data?.customer.activityName}
            />
          )}
          <KeyValuePair
            title={t("listPriceList")}
            value={
              data?.customer?.priceList?.name &&
                user.hasPermission(
                  GetPermissionSlug(
                    "shipping",
                    "price_list",
                    data?.customer?.type?.code,
                    "list"
                  )
                ) ? (
                <SpanLink
                  pathname={`/admin/price-list/${data?.customer?.priceList?.id}`}
                >
                  {data?.customer?.priceList?.name}
                </SpanLink>
              ) : (
                data?.customer?.priceList?.name
              )
            }
          />
          {customerType !== "CONCIERGE" && (
            <KeyValuePair title={t("type")} value={data?.customer.type?.name} />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("paymentMethod")}
              value={data?.customer.paymentMethod?.name}
            />
          )}
          {data?.customer?.paymentMethod?.code === "VCSH" && (
            <KeyValuePair
              title={t("walletNumber")}
              value={data?.customer?.vodafoneCash}
            />
          )}
          {data?.customer?.paymentMethod?.code === "BNK" && (
            <KeyValuePair
              title={t("bank")}
              value={data?.customer?.bank?.name}
            />
          )}
          {(data?.customer?.paymentMethod?.code === "BNK" ||
            data?.customer?.paymentMethod?.code === "INSTPY") && (
              <KeyValuePair
                title={t("accountNumber")}
                value={data?.customer?.accountNumber}
              />
            )}
          <KeyValuePair
            title={t("createdBy")}
            value={
              data?.customer?.createdBy?.username && (
                <SpanLink
                  pathname={`/admin/users/${data?.customer?.createdBy?.id}`}
                >
                  {data?.customer?.createdBy?.username}
                </SpanLink>
              )
            }
          />
          <KeyValuePair
            title={t("users")}
            value={
              data?.customer?.users &&
              data?.customer?.users.map((user, index) => (
                <SpanLink
                  key={user.id}
                  className={classes.span}
                  pathname={`/admin/users/${user.id}`}
                >
                  {user.username}{" "}
                  {!(data?.customer?.users.length - 1 === index) && " , "}
                </SpanLink>
              ))
            }
          />
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("paymentCycle")}
              value={data?.customer.paymentCycle?.name}
            />
          )}
          {data?.customer?.paymentCycle && (
            <KeyValuePair title={t("paymentDay")} value={defaultValuesList} />
          )}
          {hideMobileFrom && (
            <KeyValuePair
              title={t("hideMobileFromCode")}
              value={hideMobileFrom}
            />
          )}
        </Paper>
        {Globals.settings.eInvoicing && customerType === "MERCHANT" && (
          <Paper
            container
            className={classes.box}
            sx={{ width: "100%" }}
            component={Grid}
          >
            <KeyValuePair
              title={t("identificationType")}
              value={data?.customer.identificationType?.name}
            />
            {data?.customer.identificationType?.code !== "BUSINESS" && (
              <KeyValuePair
                title={t("idNumber")}
                value={
                  data?.customer.identificationType
                    ? data?.customer.idNumber
                    : ""
                }
              />
            )}
            {data?.customer.identificationType?.code === "BUSINESS" && (
              <KeyValuePair
                title={t("taxCardNumber")}
                value={data?.customer.taxCardNumber}
              />
            )}
          </Paper>
        )}
        <Paper
          container
          className={classes.box}
          sx={{ width: "100%" }}
          component={Grid}
        >
          <KeyValuePair
            phoneStyle={true}
            valueStyle={{ display: "inline-block", inlineSize: "max-content" }}
            title={t("phone")}
            value={<PhoneNumberActions phone={data?.customer.phone} />}
          />
          <KeyValuePair
            phoneStyle={true}
            valueStyle={{ display: "inline-block", inlineSize: "max-content" }}
            title={t("mobile")}
            value={<PhoneNumberActions mobile={data?.customer.mobile} />}
          />
          {customerType !== "CONCIERGE" && (
            <KeyValuePair title={t("mailBox")} value={data?.customer.mailBox} />
          )}
          {customerType !== "CONCIERGE" && (
            <KeyValuePair
              title={t("postalCode")}
              value={data?.customer.postalCode}
            />
          )}
          <KeyValuePair title={t("address")} value={data?.customer.address} />
          <KeyValuePair title={t("email")} value={data?.customer.email} />
        </Paper>
        <Can
          showException
          permission={!user.account && customerType !== "CONCIERGE"}
        >
          <Grid container className={classes.boxPie}>
            <PieCharts
              inputs={{ customerId: +customerId }}
              onDateRangeAccept={onDateRangeAccept}
              handleDateRange={handleDateRange}
              dateRange={{ date: queryVariables?.date }}
            />
          </Grid>
        </Can>
      </Root>
    </>
  );
};

export default CustomerView;
